<template>
<!--  <b-card-->
<!--    v-if="data"-->
<!--    body-class="pb-50"-->
<!--  >-->

<!--    <h6 class="text-uppercase">Prospects</h6>-->
<!--    <h2 class="font-weight-bolder mb-1">-->
<!--        {{statistiqueProspect.prospects ? statistiqueProspect.prospects : 0 }}-->
<!--    </h2>-->
<!--    <vue-apex-charts-->
<!--      height="70"-->
<!--      :options="statisticsOrder.chartOptions"-->
<!--      :series="data.series"-->
<!--    />-->
<!--  </b-card>-->
    <b-card
      v-if="data"
      class="card-tiny-line-stats text-center"
      body-class="pb-50"
  >
    <b-avatar
        class="mb-1"
        variant="light-danger"
        size="45"
    >
      <feather-icon
          size="21"
          icon="UsersIcon"
      />
    </b-avatar>
    <div class="truncate">
      <h2 class="mb-25 font-weight-bolder">
        {{ (statistiqueProspect && statistiqueProspect.prospects) ? statistiqueProspect.prospects : 0 }}
      </h2>
      <span>PROSPECTS</span>
    </div>

  </b-card>
</template>

<script>
import { BCard, BAvatar } from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'

const $barColor = '#f3f3f3'

export default {
  components: {
    BCard,
    BAvatar,
    VueApexCharts,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    statistiqueProspect: {
      type: [Array, Object],
      default: () => {},
    },
  },
  data() {
    return {
      statisticsOrder: {
        chartOptions: {
          chart: {
            type: 'bar',
            stacked: true,
            toolbar: {
              show: false,
            },
          },
          grid: {
            show: false,
            padding: {
              left: 0,
              right: 0,
              top: -15,
              bottom: -15,
            },
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '20%',
              startingShape: 'rounded',
              colors: {
                backgroundBarColors: [$barColor, $barColor, $barColor, $barColor, $barColor],
                backgroundBarRadius: 5,
              },
            },
          },
          legend: {
            show: false,
          },
          dataLabels: {
            enabled: false,
          },
          colors: ['#e74c3c'],

          xaxis: {
            labels: {
              show: false,
            },
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
          },
          yaxis: {
            show: false,
          },
          tooltip: {
            x: {
              show: false,
            },
          },
        },
      },
    }
  },
}
</script>
