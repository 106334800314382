<template>
  <b-card
    v-if="data"
    no-body
    class="card-developer-meetup"
  >
    <div class="rounded-top text-center" style="">
      <b-carousel
        id="carousel-fade"
        style="text-shadow: 0px 0px 1px #000"
        fade
        controls
        :interval="4000"
        class=""
      >
      <!-- <a href="https://app.livestorm.co/weedo-it/courtiers-accelerez-votre-business-digital-tout-en-conformite" target="_blank">
        <b-carousel-slide
        :img-src="require('@/assets/images/Carrousel_WEBINAR_SEPTEMBRE.png')"
      ></b-carousel-slide>
      </a>   -->
     
      <!-- <a href="https://bubble-in.com/wp-content/uploads/2022/06/Fiche-de-demande-de-partenariat-Harmonie-Mutuelle.pdf" target="_blank">
      <b-carousel-slide
        :img-src="require('@/assets/images/Codes Harmonie Mutuelle.png')"
      ></b-carousel-slide>
      </a>
      <a href="https://calendly.com/communication-24/partenariat-bubblein-weedo-market?month=2022-09" >
        <b-carousel-slide
          :img-src="require('@/assets/images/Caroussel-20220824-134919.png')"
        ></b-carousel-slide>
      </a>
       <a href="https://bit.ly/3UCSjjl" target="_blank" >
        <b-carousel-slide
          :img-src="require('@/assets/images/GABARIT_CARROUSEL_BUBBLEIN.jpg')"
        ></b-carousel-slide>
      </a>
      <a href="https://app.livestorm.co/artur-in-3/nos-3-secrets-pour-reinventer-votre-business?utm_source=bubblein&utm_medium=Mail+&utm_campaign=invitation+La+Plateforme+du+Courtage" target="_blank" >
        <b-carousel-slide
          :img-src="require('@/assets/images/newimage.jpg')"
        ></b-carousel-slide>
      </a>
      <a href="https://app.livestorm.co/bubblein/atelier-soucription-marketplace?type=detailed" target="_blank" >
        <b-carousel-slide
          :img-src="require('@/assets/images/newphotos2.jpg')"
        ></b-carousel-slide>
      </a> -->
      <!-- <a href="https://bit.ly/3i2I21H" target="_blank" >
        <b-carousel-slide
          :img-src="require('@/assets/images/Rencontre_jurid.jpg')"
        ></b-carousel-slide>
      </a> -->
      <!-- <a href="https://app.livestorm.co/bubblein/formation-produit-perform-sante-tns" target="_blank" >
        <b-carousel-slide
          :img-src="require('@/assets/images/Formation-Sante-Tns.png')"
        ></b-carousel-slide>
      </a> -->
      <a :href="carousel.url" target="_blank" v-for="carousel in carouselsData" :key="carousel.id">
        <b-carousel-slide v-if="!carousel.base64.isError"
          :img-src="carousel.base64"
        ></b-carousel-slide>
      </a>
      </b-carousel>
    </div>
  </b-card>
</template>

<script>
import {
  BCard, BImg, BCardBody, BCardText, BCardTitle, BMedia, BMediaAside, BAvatar, BAvatarGroup, VBTooltip, BMediaBody,BCarousel,BCarouselSlide
} from 'bootstrap-vue'
import ToastificationContentVue from "@/@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BCard,
    BImg,
    BCardBody,
    BCardText,
    BCardTitle,
    BMedia,
    BMediaAside,
    BAvatar,
    BAvatarGroup,
    BMediaBody,
    BCarousel,
    BCarouselSlide
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data(){
    return{
      carouselsData:[],
      currentUser: JSON.parse(localStorage.getItem('userData')),
    }
  },
   created() {
    this.fetchCarousels();
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  methods:{
     messageToast(text, title, variant) {
      this.$toast({
        component: ToastificationContentVue,
        props: {
          title,
          icon: "BellIcon",
          text,
          variant,
        },
      });
    },
    fetchCarousels() {
      this.carouselsData = [];
      this.$http
        .get("/carousels/actif")
        .then((res) => {
          if (res.data.success) {
            this.carouselsData = res.data.data;
            if(!this.currentUser.courtier_user[0].isPrincipal){
              this.carouselsData.filter((carousel) => carousel.id != 5);
            }
          } else {
            this.messageToast(
              "Nous n'avons pas pu trouver la ressource que vous avez demandée.",
              "Erreur",
              "warning"
            );
          }
          console.log(res.data);
        })
        .catch((err) => {
          this.messageToast(err.response.data.errors[0], "Erreur", "error");
          console.error(err);
        });
    },
 
  }
}
</script>

<style>
.card-developer-meetup .carousel-control-prev-icon,.card-developer-meetup .carousel-control-next-icon
{
  width: 30px;
  height: 30px;
      background-color: #767171;
}
.card-developer-meetup .carousel-caption{
  right: 0;
  left: 0;
  padding-top: 0;
  padding-bottom: 10px;
}
#carousel-fade .carousel-control-prev-icon,#carousel-fade .carousel-control-next-icon
{
  display: none;
}
#carousel-fade:hover .carousel-control-prev-icon,#carousel-fade:hover .carousel-control-next-icon
{
  display: block;
  transition: 0.3s;
}

</style>
