<template>
  <b-card
    no-body
    class="card-browser-states"
  >
    <b-card-header>
      <div>
        <b-card-title>Répartition contrats <span v-b-popover.hover.right.html="'Tous les contrats (hors concurrence) (hors résiliés, sans effet, temporaires si la date de fin est inférieur à la date du jour)'" v-ripple.400="'rgba(113, 102, 240, 0.15)'" title="Information" class="" variant="outline-primary"><feather-icon icon="HelpCircleIcon" class="text-muted cursor-pointer" size="21" /></span></b-card-title>
      </div>

      
    </b-card-header>

    <!-- body -->
    <b-card-body v-if="renderComponent">
      <b-row class="match-height">
      <b-col
              lg="4"
              md="4"
      >
      <div class="box" :class="is_assureur ? 'active' : ''" ref="box_ass" @click="showAssureurs">
        <feather-icon icon="LayersIcon" size="24" />

        <p>Assureurs</p>
      </div>
      </b-col>
      <b-col
              lg="4"
              md="4"
      >
      <div class="box" :class="is_risque ? 'active' : ''" ref="box_ris" @click="showRisques">
        <feather-icon icon="UmbrellaIcon" size="24" />

        <p>Risques</p>
      </div>
      </b-col>
      <b-col
              lg="4"
              md="4"
      >
      <div class="box-prime">
        <p class="prime_label">Prime TTC total</p>
        <p class="prime_value text-center"><b>{{totalPrimeTTC}}€</b></p>
      </div>
      </b-col>
      </b-row>
      <div
      class="browser-states"

        v-for="(sd,index) in statsData" :key="index" 
      >
      <div class=" w-100 d-flex justify-content-evenly" v-if="sd.contrat_count > 0">
         <div class="w-100 d-flex align-items-center  justify-content-start">
              <b-media style="margin-top: 9px;" no-body>
                <b-media-body>
                  <h6 class="transaction-title text-uppercase">
                    {{ is_assureur ? sd.nom_com_assureur : sd.type }}
                  </h6>
                </b-media-body>
              </b-media>
          </div>
          <div class="w-50 d-flex align-items-center justify-content-center bd-highligh">
            <span class="font-weight-bold text-body-heading mr-1">
            {{ sd.contrat_count }} ({{ getPercent(sd.contrat_count, is_assureur ? 'assureur' : 'risque') }} %)
            </span>
            <vue-apex-charts
                type="radialBar"
                height="30"
                width="30"
                :options="is_assureur ? chartDataAss[index].options : chartDataRis[index].options"
                :series="[getPercent(sd.contrat_count, is_assureur ? 'assureur' : 'risque')]"
              />
          </div>

      </div>
       
      </div>
    
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BMedia, BMediaAside, BMediaBody, BImg, BDropdown, BDropdownItem,BRow,BCol,VBPopover,VBTooltip
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import Ripple from 'vue-ripple-directive'

/* eslint-disable global-require */
const $trackBgColor = '#e9ecef'
export default {
  props:['statistiqueContratsAssRis'],
  watch: {
    statistiqueContratsAssRis: function(old, nld) {
      this.filterStateData(old)
      this.fillChartData(old)
      this.totalPrimeTTC = old.totalPrimeTTC;
    },
    statsData: function(val){
    }
  },
  components: {
    BRow,
    BCard,
    BCol,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BMediaAside,
    BMediaBody,
    BImg,
    BDropdown,
    BDropdownItem,
    VueApexCharts,
  },
  data() {
    return {
      is_assureur:true,
      is_risque:false,
      totalPrimeTTC:0,
      usedIndexes:[],
      chartDataAss:[],
      chartDataRis:[],
      chartColor: [$themeColors.primary, $themeColors.warning, $themeColors.secondary, $themeColors.info, $themeColors.danger],
     
      chart: {
        series: [90],
        options: {
          grid: {
            show: false,
            padding: {
              left: -15,
              right: -15,
              top: -12,
              bottom: -15,
            },
          },
          colors: [$themeColors.primary],
          plotOptions: {
            radialBar: {
              hollow: {
                size: '22%',
              },
              track: {
                background: $trackBgColor,
              },
              dataLabels: {
                showOn: 'always',
                name: {
                  show: false,
                },
                value: {
                  show: false,
                },
              },
            },
          },
          stroke: {
            lineCap: 'round',
          },
        },
      },
      renderComponent:false,
      statsData:[],
      totalStateDataAss:0,
      totalStateDataRis:0
    }
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-popover': VBPopover,
    Ripple
  },
  created() {
  },
  
  methods:{
    
   
    showAssureurs(){
      this.statsData = this.statistiqueContratsAssRis.contratsAssureurs.slice(0,5);
      this.is_assureur = true;
      this.is_risque = false;
    },
    showRisques(){
      this.statsData = this.statistiqueContratsAssRis.contratsRisques;
      this.is_assureur = false;
      this.is_risque = true;

    },
    filterStateData(data){
    this.statsData = this.statistiqueContratsAssRis.contratsAssureurs.slice(0,5);
      data.contratsAssureurs.map((dt)=>{
        this.totalStateDataAss+=dt.contrat_count
      })
      data.contratsRisques.map((dt)=>{
        this.totalStateDataRis+=dt.contrat_count
      })
      this.renderComponent=true
    },
    getPercent(value,type) {
      return type == 'assureur' ? ((value/this.totalStateDataAss)*100).toFixed(1) : ((value/this.totalStateDataRis)*100).toFixed(1)
    },
    fillChartData(data){
      this.chartDataRis = [];
      this.chartDataAss = [];
      for (let i = 0; i < data.contratsAssureurs.length; i += 1) {
      const chartClone = JSON.parse(JSON.stringify(this.chart))
      chartClone.options.colors[0] = this.chartColor[this.getRandomIndex()]
      this.chartDataAss.push(chartClone)
    }
    for (let i = 0; i < data.contratsRisques.length; i += 1) {
      const chartClone = JSON.parse(JSON.stringify(this.chart))
      chartClone.options.colors[0] = this.chartColor[this.getRandomIndex()]
      this.chartDataRis.push(chartClone)
    }

    },
    getRandomIndex() {
    // Check if all indexes have been used
    if (this.usedIndexes.length === this.chartColor.length) {
      // If all indexes have been used, reset the usedIndexes array
      this.usedIndexes = []
    }
    
    // Generate a random index until an unused index is found
    let index
    do {
      index = Math.floor(Math.random() * this.chartColor.length)
    } while (this.usedIndexes.includes(index))
    
    // Add the index to the usedIndexes array and return it
    this.usedIndexes.push(index);
    
    return index;
  },
   formatPrime(price) {
  const priceString = price.toFixed(2);
  const decimalIndex = priceString.indexOf('.');
  const integerPart = priceString.slice(0, decimalIndex);
  const decimalPart = priceString.slice(decimalIndex + 1);
  const integerWithSeparator = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  const formattedPrice = integerWithSeparator + ',' + decimalPart;
  return formattedPrice
}


  },
}
</script>
<style>
.box{
  width: 100% !important;
  height:90px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 1px solid #ccc;
  border-radius: 6px;
  cursor: pointer;
}
.box p{
  margin-bottom: 0 !important;
  font-size: 1rem;
}
.box-prime p{
 margin-bottom: 0 !important;
}
.box.active{
  border-color: #4D25BC;
}
.prime_label{
  font-size: 0.95rem;
}
.prime_value{
  font-size: 1.1rem;
}
.box-prime{
  height: 90px;
  width: 100%;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>