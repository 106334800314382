<template>
<!--  <b-card-->
<!--      v-if="data"-->
<!--      body-class="pb-50"-->
<!--      class="card-tiny-line-stats"-->
<!--  >-->
<!--    <h6>PROJETS</h6>-->
<!--    <h2 class="font-weight-bolder mb-1">-->
<!--      {{ statistiqueProjetsDevis.projets ? statistiqueProjetsDevis.projets : 0 }}-->
<!--    </h2>-->
<!--    <vue-apex-charts-->
<!--        :options="statisticsProfit.chartOptions"-->
<!--        :series="data.series"-->
<!--        height="70"-->
<!--    />-->
<!--  </b-card>-->

  <b-card
      v-if="data"
      body-class="pb-50"
      class="card-tiny-line-stats text-center"
  >
    <b-avatar
        class="mb-1"
        size="45"
        variant="light-primary"
    >
      <feather-icon
          icon="ApertureIcon"
          size="21"
      />
    </b-avatar>
    <div class="truncate">
      <h2 class="mb-25 font-weight-bolder">
        {{ (statistiqueProjetsDevis && statistiqueProjetsDevis.projets) ? statistiqueProjetsDevis.projets : 0 }}
      </h2>
      <span>DEVIS</span>
    </div>

  </b-card>
</template>

<script>
import { BAvatar, BCard } from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'

const $trackBgColor = '#EBEBEB'

export default {
  components: {
    BCard,
    BAvatar,
    VueApexCharts,
  },
  props: {
    data: {
      type: Object,
      default: () => {
      },
    },
    statistiqueProjetsDevis: {
      type: [Array, Object],
      default: () => {
      },
    },
  },
  data() {
    return {
      statisticsProfit: {
        chartOptions: {
          chart: {

            type: 'line',
            toolbar: {
              show: false,
            },
            zoom: {
              enabled: false,
            },
          },
          grid: {
            borderColor: $trackBgColor,
            strokeDashArray: 5,
            xaxis: {
              lines: {
                show: true,
              },
            },
            yaxis: {
              lines: {
                show: false,
              },
            },
            padding: {
              top: -30,
              bottom: -10,
            },
          },
          stroke: {
            width: 3,
          },
          colors: ['#4d25bc'],
          markers: {
            size: 2,
            colors: '#4d25bc',
            strokeColors: '#4d25bc',
            strokeWidth: 2,
            strokeOpacity: 1,
            strokeDashArray: 0,
            fillOpacity: 1,
            discrete: [
              {
                seriesIndex: 0,
                dataPointIndex: 5,
                fillColor: '#ffffff',
                strokeColor: '#4d25bc',
                size: 5,
              },
            ],
            shape: 'circle',
            radius: 2,
            hover: {
              size: 3,
            },
          },
          xaxis: {
            labels: {
              show: true,
              style: {
                fontSize: '0px',
              },
            },
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
          },
          yaxis: {
            show: false,
          },
          tooltip: {
            x: {
              show: false,
            },
          },
        },
      },
    }
  },
}
</script>
